<style scoped>
h3,
.rn-tpl2 .rn-h1 {
  color: #333;
  font-weight: normal;
  color: var(--titleColor);
  margin-bottom: 2px;
  font-size: 15px;
  overflow: hidden;
}
.rn-tpl2,
.rn-small-video {
  display: block;
  text-decoration: none;
  padding: 5px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.rn-one-pic-wrap img,
.rn-small-video-wrap img,
.rn-three-pic-wrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.rn-one-pic-wrap,
.rn-small-video-wrap {
  position: relative;
  /* width: 33.65%; */
  width: 35.65%;
  padding-top: 22.222%;
}
.rn-text-content {
  -webkit-flex: 1;
  flex: 1;
  margin-left: 14px;
}
.content_article {
  color: var(--titleSecondaryColor);
}
.round {
  height: 50px;
  width: 50px;
  line-height: 50px;
  padding-left: 5px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 20px;
  border: none;
  color: white;
}

.rn-footer-gaojian {
  position: relative;
  height: auto !important;
  margin-top: -2px;
  margin-bottom: 2px;

  color: #999;
}
.rn-footer-gaojian span {
  font-size: 12px;
  line-height: normal;
  display: inline-block;
  text-overflow: ellipsis;
}

.rn-three-pic-content {
  margin-bottom: 10px;
  display: flex;
}
.rn-three-pic-wrap {
  width: 33.333%;
  height: 22.222%;
  line-height: 22.2222%;
  height: auto;
  padding-top: 22.2222%;
  display: inline-block;
  margin-right: 1px;
  vertical-align: top;
  position: relative;
}
.rn-three-pic-wrap {
  width: 33.105%;
  padding-top: 21.99%;
}
.activity-container .selectContent {
  flex: 1;
}
.selectIcon {
  background-size: 100% 100%;
}
p {
  margin-bottom: 0;
}
p.active {
  color: #f85959;
  font-weight: 700;
}
.gaojian_label {
  padding: 0 4px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
}
</style>
<template>
  <div style="text-align: left">
    <div>
      <template v-if="contentData.type == 'image'">
        <!--   -->
        <div class="rn-tpl2" @click="goDetail(contentData)">
          <div class="rn-one-pic-wrap">
            <img :src="`https://gt-wx.gtrmt.cn/${contentData.img[0]}`" />
          </div>
          <div class="rn-text-content">
            <div
              class="rn-h1"
              style="
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              "
            >
              {{ contentData.title }}
            </div>
            <div class="rn-footer-gaojian">
              <div
                style="
                  display: flex;
                  display: -webkit-flex;
                  display: flex;
                  flex-wrap: wrap;

                  align-items: center;
                "
              >
                <div style="flex: 1">
                  <span style="color: rgb(123 21 21)" class="gaojian_label"
                    >状态:{{ getReviewStatus(contentData.review) }}</span
                  >
                  <span
                    style="color: red"
                    class="gaojian_label"
                    v-if="contentData.colnumTop"
                    >置顶↑
                  </span>

                  <span
                    style="color: #ed4014"
                    class="gaojian_label"
                    v-if="contentData.keywordsStr"
                  >
                    关键字:{{ contentData.keywordsStr }}</span
                  >
                  <span
                    style="color: #2988a0"
                    class="gaojian_label"
                    v-if="contentData.mediaName"
                    >媒体:{{ contentData.mediaName }}</span
                  >
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-top: 4px;
                  justify-content: space-between;
                "
              >
                <div v-if="contentData.author">
                  {{ contentData.author }}
                  <span v-if="contentData.mediaDeptName"
                    >({{ contentData.mediaDeptName }})
                  </span>
                </div>
                <div v-if="contentData.updated">
                  {{ contentData.updated | dateFormat }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="contentData.type === 'text'">
        <div @click.stop="goDetail(contentData)">
          <h3
            style="
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            "
          >
            {{ contentData.title }}
          </h3>
          <div class="rn-footer-gaojian">
            <div
              style="
                display: flex;
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;

                align-items: center;
              "
            >
              <div style="flex: 1">
                <span style="color: rgb(123 21 21)" class="gaojian_label"
                  >状态:{{ getReviewStatus(contentData.review) }}</span
                >
                <span
                  style="color: red"
                  class="gaojian_label"
                  v-if="contentData.colnumTop"
                  >置顶↑
                </span>

                <span
                  style="color: #ed4014"
                  class="gaojian_label"
                  v-if="contentData.keywordsStr"
                >
                  关键字:{{ contentData.keywordsStr }}</span
                >
                <span
                  style="color: #2988a0"
                  class="gaojian_label"
                  v-if="contentData.mediaName"
                  >媒体:{{ contentData.mediaName }}</span
                >
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 4px;
                justify-content: space-between;
              "
            >
              <div v-if="contentData.author">
                作者: {{ contentData.author }}
              </div>
              <div v-if="contentData.updated">
                {{ contentData.updated | dateFormat }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import debug from "debug";
import moment from "moment";

moment.locale("zh-cn");

const _d = debug("@pages:rnContent");

export default {
  name: "rnContentTouGaoCom",
  data() {
    return {};
  },
  props: {
    // 内容数据
    contentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 视频展示结构 左右/上下
    layoutStyle: {
      type: String,
      default: "toLeft",
    },
    isSelfDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 审核状态显示
    getReviewStatus(review) {
      switch (review) {
        case "0":
          return "待审核";
        case "1":
          return "审核通过";
        case "-1":
          return "未通过";
        case "2":
          return "草稿";
        default:
          return "草稿";
      }
    },
    goDetail(data) {
      // this.$emit("toDetail");
      // if (this.isSelfDetail) {
      //   // 自定义
      //   this.$emit("goDetail", data);
      // } else {
      //   // 精选记录位置定
      //   this.$store.state.mainTop =
      //     document.body.scrollTop || document.documentElement.scrollTop;
      //   _d(
      //     "精选记录位置定位-----",
      //     document.body.scrollTop,
      //     document.documentElement.scrollTop
      //   );
      //   /**
      //    * 路由跳转
      //    * 活动优先：投票活动 调查问卷活动 答题活动 报名活动 留言活动
      //    * 单视频
      //    * 一般文章
      //    */
      //   let url = "";
      //   const like = data.likeStatus ? "1" : "2";
      //   const collection = data.collectionStatus ? "1" : "2";
      //   if (data.activityType == "投票活动") {
      //     console.log("投票活动----");
      //     url = `/voteDetail?id=${data.activityId}&contentId=${data._cid}&source=column`;
      //   } else if (
      //     data.activityType == "调查问卷活动" ||
      //     data.activityType == "答题活动" ||
      //     data.activityType == "报名活动" ||
      //     data.activityType == "留言活动"
      //   ) {
      //     console.log("其他活动----");
      //     url = `/detailCon?id=${data.id}&like=${like}&collection=${collection}&isActivity=1&activityFrom=column`;
      //   } else if (data.singleVideo || data.wxTVideo) {
      //     console.log("单视频----");
      //     url = `/detailVideoCon?id=${data.id}&like=${like}&collection=${collection}`;
      //   } else {
      //     console.log("一般文章----");
      //     url = `/detailCon?id=${data.id}&like=${like}&collection=${collection}`;
      //   }
      //   this.$router.push({ path: url });
      // }
    },
  },
  filters: {
    dateFormat(time) {
      return moment(time).format("YYYY.MM.DD");
    },
  },
};
</script>
