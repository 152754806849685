<template>
  <div
    style=""
    class="notification fixed"
    v-if="show"
    transition="slide"
    :style="setStyle"
  >
    <div class="delete" @click="close()"></div>
    <div class="content">{{ options.content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      // eslint-disable-next-line arrow-body-style
      default: () => {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setStyle() {
      return {
        color: this.options.textColor || "#fff",
        background: this.options.backgroundColor || "rgba(28,36,56,0.4)",
      };
    },
  },
  methods: {
    countdown() {
      if (this.options.autoClose) {
        const t = setTimeout(() => {
          this.close();
        }, this.options.showTime || 3000);
        this.timers.push(t);
      }
    },
    close() {
      this.show = false;
      this.options = {};
    },
  },
  watch: {
    options() {
      this.timers.forEach((timer) => {
        window.clearTimeout(timer);
      });
      this.timers = [];
      this.countdown();
    },
  },
};
</script>

<style scoped >
.slide-transition {
  transition: all 0.3s ease;
  transform: translate3d(0, 0, 0);
}
.slide-transition .slide-enter,
.slide-transition .slide-leave {
  transform: translate3d(0, -100%, 0);
}
.slide-transition .delete {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: rgba(51, 51, 51, 0.2);
  cursor: pointer;
  display: inline-block;
  height: 24px;
  position: relative;
  vertical-align: top;
  width: 24px;
  float: right;
}
.slide-transition .delete::before {
  background: #fff;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  margin-left: -25%;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 50%;
}
.slide-transition .delete::before {
  transform: rotate(45deg);
}
.slide-transition .delete::after {
  transform: rotate(-45deg);
}
.slide-transition .delete:hover {
  background: rgba(51, 51, 51, 0.5);
}
.notification {
  text-align: center;
  width: 60%;
  line-height: 2;
  z-index: 3;
  position: fixed;
  top: 40%;
  left: 20%;
  border-radius: 0.2rem;
  font-weight: bold;
  z-index: 1006;
  /*  modal z-index:1003 该值需大于1003 才可显示 */
}
.content {
  padding: 0.6rem 2rem;
}
</style>
