<style scoped>
.rn-footer {
  position: relative;
}
.rn-footer {
  height: auto !important;
  margin-top: -2px;
  margin-bottom: 2px;
  display: -webkit-flex;
  display: flex;
  color: #999;
  flex-wrap: wrap;
  align-items: center;
}
.rn-footer span {
  font-size: 13px;
  line-height: normal;
  display: inline-block;
  text-overflow: ellipsis;
}

.rn-three-pic-content {
  margin-bottom: 10px;
  display: flex;
}
.rn-three-pic-wrap {
  width: 33.333%;
  height: 22.222%;
  line-height: 22.2222%;
  height: auto;
  padding-top: 22.2222%;
  display: inline-block;
  margin-right: 1px;
  vertical-align: top;
  position: relative;
}
.rn-three-pic-wrap {
  width: 33.105%;
  padding-top: 21.99%;
}
.activity-container .selectContent {
  flex: 1;
}
.selectIcon {
  background-size: 100% 100%;
}
p {
  margin-bottom: 0;
}
p.active {
  color: #f85959;
  font-weight: 700;
}
</style>
<template>
  <div>
    <div>
      <div class="rn-footer" style="display: flex">
        <div style="flex: 1; width: 100%; display: flex">
          <div style="flex: 1; color: #853c37; color: red">
            <span v-if="contentData.colnumTop">置顶 </span>
          </div>
          <div style="flex: 1">
            <span class="view_time">{{
              getReviewStatus(contentData.status)
            }}</span>
          </div>
        </div>
        <div v-if="contentData.updated">
          <span class="view_time">{{ contentData.updated | dateFormat }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debug from "debug";
import moment from "moment";

moment.locale("zh-cn");
const _d = debug("@pages:rnGaoijanFooter");

export default {
  name: "rnGaoijanFooter",
  data() {
    return {};
  },
  props: {
    // 内容数据
    contentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    // 审核状态显示
    getReviewStatus(review) {
      switch (review) {
        case "0":
          return "待审核";
        case "1":
          return "审核通过";
        case "-1":
          return "未通过";
        case "2":
          return "草稿";
        default:
          return "草稿";
      }
    },
  },
  filters: {
    dateFormat(time) {
      return moment(time).format("YYYY.MM.DD");
    },
  },
  created() {},
};
</script>
