<style scoped>
.confirmCon {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 74%);
  width: 100vw;
  height: 100vh;
}
.confirm {
  width: 80%;
  /* height: 180px; */
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: 40px 20px 30px;
  margin: 60% auto 0;
  border-radius: 8px;
}
.confirm p {
  font-size: 23px;
  color: #222;
}
.confirm .btns {
  width: 200px;
  margin: 30px auto 0;
}
.confirm .btns button {
  font-size: 18px;
  width: 80px;
  height: 40px;
  color: #fff;
  background: #f33f31;
  border-radius: 5px;
}

.confirm .btns .sure {
  background: #3c9654;
  margin-right: 10px;
}
.confirm .btns .cancel {
  background: #be5050;
}
</style>
<template>
  <div class="confirmCon">
    <div class="confirm">
      <p>{{ title }}</p>
      <div class="btns">
        <button class="sure" @click="sure">确认</button>
        <button class="cancel" @click="cancel">取消</button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "answerFill",
  props: ["prodata", "title"],
  data() {
    return {
      problem: this.prodata,
    };
  },
  created() {},
  methods: {
    //暂不
    sure() {
      this.$emit("sure");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
};
</script>
